import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });
import createPersistedState from "vuex-persistedstate";
import user from "@/store/user";
import {exam} from "@/store/exam";

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user:user,
    exam,

  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
