import axios from "axios";
import { api } from "@/main";
import { openWindow } from "@/hooks/openWindow";

const user = {
  namespace: true,
  state: {
    auth: "",
    jobs: "",
    jobDetails: "",
    location: "",
    department: "",
    interest: "",
    state: "",
    user: "",
    work: "",
    education: "",
    savedjobs: "",
    appliedjobs: "",
    screenedjobs: "",
    recommenedJobs: "",
    search: "",
    site_image: "",
    site_settings: "",
  },
  getters: {
    getAuth: (state) => state.auth,
    getJobs: (state) => state.jobs,
    getJobDetails: (state) => state.jobDetails,
    getLocation: (state) => state.location,
    getDepartment: (state) => state.department,
    getInterest: (state) => state.interest,
    getState: (state) => state.state,
    getUserDetails: (state) => state.user,
    getWorkExperience: (state) => state.work,
    getEducation: (state) => state.education,
    getSavedJobs: (state) => state.savedjobs,
    getAppliedJobs: (state) => state.appliedjobs,
    getScreenedJobs: (state) => state.screenedjobs,
    getRecommendedJobs: (state) => state.recommenedJobs,
    getSearchJobs: (state) => state.search,
    getSiteImage: (state) => state.site_image,
    getSiteSettings: (state) => state.site_settings,
  },
  mutations: {
    updateAuth: (state, data) => (state.auth = data),
    updateJobs: (state, data) => (state.jobs = data),
    updateJobDetails: (state, data) => (state.jobDetails = data),
    updateLocation: (state, data) => (state.location = data),
    updateDepartment: (state, data) => (state.department = data),
    updateInterest: (state, data) => (state.interest = data),
    updateState: (state, data) => (state.state = data),
    updateUserDetails: (state, data) => (state.user = data),
    updateWorkExperience: (state, data) => (state.work = data),
    updateEducation: (state, data) => (state.education = data),
    updateSavedJobs: (state, data) => (state.savedjobs = data),
    updateAppliedJobs: (state, data) => (state.appliedjobs = data),
    updateScreenedJobs: (state, data) => (state.screenedjobs = data),
    updateRecommendedJobs: (state, data) => (state.recommenedJobs = data),
    updateSearchedJobs: (state, data) => (state.search = data),
    updateSiteImage: (state, data) => (state.site_image = data),
    updateSiteSettings: (state, data) => (state.site_settings = data),
  },
  actions: {
    UserLogin: async (context, data) => {
      try {
        return await axios.post(`${api}/login`, {
          email: data.email,
          password: data.password,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    ForgotPassword: async (context, data) => {
      try {
        return await axios.post(`${api}/forgot-password`, {
          email: data.email,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    ResetPassword: async (context, data) => {
      try {
        return await axios.post(`${api}/reset-password`, {
          id: data.id,
          password: data.password,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    Register: async (context, data) => {
      try {
        return await axios.post(`${api}/register`, {
          email: data.email,
          password: data.password,
          fname: data.firstname,
          lname: data.lastname,
          phone: data.phone,
          dob: data.dob,
          gender: data.gender,
          nationality: data.nationality,
          state: data.state,
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchState: async (context) => {
      try {
        await axios
          .get(`${api}/getStates`)
          .then((response) => {
            if (response.status)
              return context.commit("updateState", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchLocations: async (context) => {
      try {
        await axios
          .get(`${api}/getStates`)
          .then((response) => {
            if (response.status)
              return context.commit("updateLocation", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchJobDetails: async (context, data) => {
      try {
        await axios
          .get(`${api}/job/${data.slug}/${context.getters.getAuth.id || null}`)
          .then((response) => {
            if (response.status)
              return context.commit("updateJobDetails", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchJobs: async (context) => {
      try {
        await axios
          .get(`${api}/jobs/${context.getters.getAuth.id || null}`)
          .then((response) => {
            if (response.status)
              return context.commit("updateJobs", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchUserDetails: async (context) => {
      try {
        await axios
          .get(`${api}/userinfo/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateUserDetails", response.data.Info);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchDepartments: async (context) => {
      try {
        await axios
          .get(`${api}/departments`)
          .then((response) => {
            if (response.status)
              return context.commit("updateDepartment", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchWorkExperience: async (context) => {
      try {
        await axios
          .get(`${api}/wexp/read/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateWorkExperience", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchEducation: async (context) => {
      try {
        await axios
          .get(`${api}/edu/read/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateEducation", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    toggleSaveList: async (context, data) => {
      try {
        return await axios.post(
          `${api}/wishlist`,
          {
            app_id: data.id,
            job_id: data.jobID,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateInterest: async (context, data) => {
      try {
        return await axios.post(
          `${api}/update-interest`,
          {
            id: context.getters.getAuth.id,
            interest: data.interests,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateAbout: async (context, data) => {
      try {
        return await axios.post(
          `${api}/update-aboutme`,
          {
            id: context.getters.getAuth.id,
            aboutme: data.about,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    AddWorkExperience: async (context, data) => {
      try {
        return await axios.post(
          `${api}/wexp/create`,
          {
            app_id: context.getters.getAuth.id,
            jobtitle: data.jobtitle,
            employer: data.employer,
            startdate: data.startdate,
            enddate: data.enddate,
            jobdesc: data.jobdesc,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    AddEducation: async (context, data) => {
      try {
        return await axios.post(
          `${api}/edu/create`,
          {
            app_id: context.getters.getAuth.id,
            institution: data.institution,
            qualification: data.qualification,
            min_qual: data.min_qual,
            startdate: data.startdate,
            enddate: data.enddate,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateSkills: async (context, data) => {
      try {
        return await axios.post(
          `${api}/update-skills`,
          {
            id: context.getters.getAuth.id,
            skills: data.skills,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateWorkExperience: async (context, data) => {
      try {
        return await axios.post(
          `${api}/wexp/update`,
          {
            app_id: context.getters.getAuth.id,
            jobtitle: data.jobtitle,
            employer: data.employer,
            startdate: data.startdate,
            enddate: data.enddate,
            jobdesc: data.jobdesc,
            id: data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateEducation: async (context, data) => {
      try {
        return await axios.post(
          `${api}/edu/update`,
          {
            app_id: context.getters.getAuth.id,
            institution: data.institution,
            qualification: data.qualification,
            min_qual: data.min_qual,
            startdate: data.startdate,
            enddate: data.enddate,
            id: data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    DeleteEducation: async (context, data) => {
      try {
        return await axios.delete(`${api}/edu/remove/${data.id}`, {
          headers: { Authorization: `Bearer ${context.getters.getAuth.token}` },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    DeleteWorkExperience: async (context, data) => {
      try {
        return await axios.delete(`${api}/wexp/remove/${data.id}`, {
          headers: { Authorization: `Bearer ${context.getters.getAuth.token}` },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    completeProfile: async (context) => {
      try {
        return await axios.post(
          `${api}/complete-profile`,
          {
            id: context.getters.getAuth.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    resendVerificationEmail: async (context) => {
      try {
        return await axios.post(
          `${api}/resend-ver-email`,
          {
            id: context.getters.getAuth.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UploadResume: async (context, data) => {
      try {
        let form = new FormData();
        form.append("id", context.getters.getAuth.id);
        form.append("resume", data.resume);
        return await axios.post(`${api}/upload-resume`, form, {
          headers: {
            Authorization: `Bearer ${context.getters.getAuth.token}`,
            "content-type": "multipart/form-data",
          },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    UploadAvatar: async (context, data) => {
      try {
        let form = new FormData();
        form.append("id", context.getters.getAuth.id);
        form.append("image", data.avatar);
        return await axios.post(`${api}/upload-avatar`, form, {
          headers: {
            Authorization: `Bearer ${context.getters.getAuth.token}`,
            "content-type": "multipart/form-data",
          },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    DeleteResume: async (context) => {
      try {
        return await axios.post(
          `${api}/remove-resume`,
          {
            id: context.getters.getAuth.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    UpdateProfile: async (context, data) => {
      try {
        return await axios.post(
          `${api}/update-profile`,
          {
            id: context.getters.getAuth.id,
            email: data.email,
            password: data.password,
            fname: data.firstname,
            lname: data.lastname,
            phone: data.phone,
            dob: data.dob,
            gender: data.gender,
            nationality: data.nationality,
            state: data.state,
            address: data.address,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchSavedJobs: async (context) => {
      try {
        await axios
          .get(`${api}/saved-jobs/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateSavedJobs", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchAppliedJobs: async (context) => {
      try {
        await axios
          .get(`${api}/applied-jobs/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateAppliedJobs", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchScreenedJobs: async (context) => {
      try {
        await axios
          .get(`${api}/screened-jobs/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit("updateScreenedJobs", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    FetchRecommendedJobs: async (context) => {
      try {
        await axios
          .get(`${api}/job-recommended/${context.getters.getAuth.id}`, {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          })
          .then((response) => {
            if (response.status)
              return context.commit(
                "updateRecommendedJobs",
                response.data.data
              );
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    SearchJobs: async (context, data) => {
      try {
        await axios
          .post(
            `${api}/search`,
            {
              what: data.what,
              where: data.where,
            },
            {
              headers: {
                Authorization: `Bearer ${context.getters.getAuth.token}`,
              },
            }
          )
          .then((response) => {
            if (response.status)
              return context.commit("updateSearchedJobs", response.data.data);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },
    ChangePassword: async (context, data) => {
      try {
        return await axios.post(
          `${api}/change-password`,
          {
            id: context.getters.getAuth.id,
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    ApplyForJobs: async (context, data) => {
      try {
        return await axios.post(
          `${api}/job/apply`,
          {
            app_id: context.getters.getAuth.id,
            job_id: data.jobID,
            mode: data.mode,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    fetchOauthUrl: async (context) => {
      try {
        await axios.get(`${api}/auth-login`).then((response) => {
          openWindow(response.data, "Microsoft Login");
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    fetchSiteImage: async (context) => {
      try {
        await axios.get(`${api}/site-settings`).then((response) => {
          if (response.data.status)
            return context.commit("updateSiteImage", response.data.data);
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    fetchSiteSettings: async (context) => {
      try {
        await axios.get(`${api}/site-data`).then((response) => {
          if (response.data.status)
            return context.commit("updateSiteSettings", response.data.data);
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    logUserOut: async (context) => {
      try {
        return await axios.post(
          `${api}/logout`,
          {
            id: context.getters.getAuth.id,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    acceptJob: async (context, data) => {
      try {
        return await axios.post(
          `${api}/accept-offer`,
          {
            id: data.job_id,
            action: data.action,
            reason: data.reason,
            profile_experience: data.profile_experience,
            cbt_experience: data.cbt_experience,
            interview_experience: data.interview_experience,
            remarks: data.remarks,
          },
          {
            headers: {
              Authorization: `Bearer ${context.getters.getAuth.token}`,
            },
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};

export default user;
