const userRoutes = [
  {
    name: "home",
    path: "/",
    component: () =>
      import(/*webpackChunkName: "index" */ "../views/home/home"),
    meta: {
      title: "Home - 1app Career Portal",
    },
  },
  {
    name: "jobs",
    path: "/jobs",
    query: "page=all",
    component: () => import(/*webpackChunkName: "job" */ "../views/home/jobs"),
    meta: {
      title: "Job Listing - 1app Career Portal",
    },
  },
  {
    name: "search",
    path: "/search-job",
    component: () =>
      import(/*webpackChunkName: "search-job" */ "../views/home/search"),
    meta: {
      title: "Search Job- 1app Career Portal",
    },
  },
  {
    name: "contact",
    path: "/contact-us",
    component: () =>
      import(/*webpackChunkName: "contact" */ "../views/home/contact"),
    meta: {
      title: "Contact Us - 1app Career Portal",
    },
  },
  {
    name: "login",
    path: "/login",
    component: () => import(/*webpackChunkName: "login" */ "../views/login"),
    meta: {
      title: "Login - 1app Career Portal",
    },
  },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: () =>
      import(
        /*webpackChunkName: "forgot-password" */ "../views/forgotPassword"
      ),
    meta: {
      title: "Forgot Password - 1app Career Portal",
    },
  },
  {
    name: "resetPassword",
    path: "/reset-password/:enc/:id/:uniqid",
    component: () =>
      import(/*webpackChunkName: "reset-password" */ "../views/resetPassword"),
    meta: {
      title: "Reset Password - 1app Career Portal",
    },
  },
  {
    name: "auth-login",
    path: "/auth-login",
    component: () =>
      import(/*webpackChunkName: "auth-login" */ "../views/auth-login"),
    meta: {
      title: "Authenticating Staff - 1app Career Portal",
    },
  },
  {
    name: "register",
    path: "/create-account",
    component: () =>
      import(/*webpackChunkName: "register" */ "../views/register"),
    meta: {
      title: "Create Account - 1app Career Portal",
    },
  },
  {
    name: "profile",
    path: "/user/profile",
    component: () =>
      import(/*webpackChunkName: "user-profile" */ "../views/user/profile"),
    meta: {
      title: "My Profile- 1app Career Portal",
      auth: "user",
    },
  },
  {
    name: "my-jobs",
    path: "/user/jobs",
    component: () =>
      import(/*webpackChunkName: "user-jobs" */ "../views/user/my-jobs"),
    meta: {
      title: "My Jobs- 1app Career Portal",
      auth: "user",
    },
  },
  {
    name: "jobDetails",
    path: "/jobs/:slug",
    component: () =>
      import(
        /*webpackChunkName: "user-job-details" */ "../views/home/jobDetails"
      ),
    meta: {
      title: "Job Details- 1app Career Portal",
    },
    params: true,
  },
  {
    name: "profile-completion",
    path: "/user/complete-profile",
    component: () =>
      import(
        /*webpackChunkName: "user-profile-completion" */ "../views/user/profile-completion"
      ),
    meta: {
      title: "Complete Profile- 1app Career Portal",
      auth: "user",
    },
  },
  {
    name: "settings",
    path: "/user/settings",
    component: () =>
      import(/*webpackChunkName: "user-settings" */ "../views/user/settings"),
    meta: {
      title: "Account Settings - 1app Career Portal",
      auth: "user",
    },
  },
  {
    name: "exam",
    path: "/cbt/exam",
    component: () =>
      import(/*webpackChunkName: "user-exam" */ "../views/cbt/exam"),
    meta: {
      title: "Recruitment Exam - 1app Career Portal",
      auth: "user",
    },
  },

  {
    name: "notFound",
    path: "*",
    component: () => import(/*webpackChunkName: "error" */ "../views/notFound"),
    meta: {
      title: "Error 404- 1app Career Portal",
    },
  },
];

export default userRoutes;
